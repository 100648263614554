import React from 'react'
import { NavLink } from 'react-router-dom'

const CardOrder = ({ order }) => {
    return (
        <div className='flex items-center justify-between py-2 w-full hover:bg-zinc-200 rounded-full px-4'>
            <div className='flex items-center gap-1'>
                <p className='font-medium'># {order.id}</p>
                {!order.kot ? <span className='w-2 h-2 rounded-full bg-emerald-500'></span> : null}
            </div>
            <div className='flex justify-end gap-2 items-center'>
                <p className={`rounded-full ${order.kot ? 'bg-green-300' : 'bg-green-500' } text-white px-3`}><i className="fa-solid fa-chair"></i> {order.table.table_code}</p>
                <a href={`/orders/print/kitchen/${order.id}`} title='Kitchen Recept'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z" />
                    </svg>
                </a>
                <p className='w-20 text-right'>₹ {order.total}</p>
                <NavLink to={`/orders/${order.id}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                    </svg>
                </NavLink>
            </div>
        </div>
    )
}

export const PendingOrdersCard = ({ orders }) => {
    return (
        <div className=' py-4 bg-white rounded-lg shadow-md dark:bg-gray-800'>
            <p className="text-lg font-bold text-gray-700 dark:text-white mb-6 px-8">Open Orders</p>
            <div className='px-4'>
                {orders.map(order => <CardOrder key={order.id} order={order} />)}
            </div>
        </div>
    )
}
